<template>
  <div class="bodyEncaminhada">
    <p class="titleProposta">Proposta encaminhada</p>
    <div class="master-wrapper">
      <div class="circle-wrapper">
        <div class="circle-ativo">1</div>
      </div>
      <div class="circle-wrapper">
        <div class="circle-ativo">2</div>
      </div>
      <div class="circle-wrapper">
        <div class="circle-ativo">3</div>
      </div>
    </div>
    <div class="alignMobile">
      <img
        style="margin: 15px auto; display: block"
        src="/img/logo_pagseguro.png"
        width="250px"
        alt="PagSeguro Logo"
      />
      <div v-if="this.contraProposta != 'contraProposta'">
        <p class="mb-1" style="font-weight: bold">
          Sua proposta foi encaminhada
        </p>
        <p class="mb-1">Parabéns pela negociação!</p>
        <p class="mb-8">Em breve entraremos em contato</p>
        <p style="font-weight: bold">Valor negociado:</p>
        <div v-if="this.proposta == 'avista'">
          <p style="font-weight: bold">R$ {{ this.valorTotal }}</p>
        </div>
        <div v-if="this.proposta == 'parcelado'">
          <p class="mt-2">entrada de</p>
          <p style="font-weight: bold">R$ {{ this.valorEntrada }}</p>
          <p style="font-weight: bold">
            + {{ this.qtdeParcelas }}x {{ this.valorParcela }}
          </p>
        </div>
      </div>
      <div v-if="this.contraProposta == 'contraProposta'">
        <p style="font-weight: bold">Sua contra-proposta foi encaminhada</p>
        <p>Parabéns pela negociação!</p>
        <p class="mb-2">Em breve entraremos em contato</p>
      </div>
      <p class="mt-8">Telefone informado</p>
      <p style="font-weight: bold">{{ tel }}</p>
      <p class="mt-8">Qualquer dúvida entrar em contrato</p>
      <a
        style="text-decoration: none; color: #212121"
        href="https://api.whatsapp.com/send?phone=+5517988429794&text=Olá, estou com dúvida"
      >
        <div class="d-flex align-center justify-center mt-3 mb-3">
          <v-icon size="35px" color="green darken-2" class="mr-2">
            mdi-whatsapp
          </v-icon>
          <span class="numberWhats">(17) 98842-9794</span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "PropostaEncaminhada",
  data() {
    return {
      valorTotal: null,
      tel: null,
      qtdeParcelas: null,
      valorEntrada: null,
      valorParcela: null,
      valorTotal: null,
      proposta: null,
      contraProposta: null,
    };
  },
  created() {
    this.tel = localStorage.getItem("tel");
    this.qtdeParcelas = localStorage.getItem("qtdeParcelas");
    this.valorEntrada = localStorage.getItem("valorEntrada");
    this.valorParcela = localStorage.getItem("valorParcela");
    this.valorTotal = localStorage.getItem("valorTotal");
    this.proposta = localStorage.getItem("proposta");
    this.contraProposta = localStorage.getItem("contraProposta");
  },
  mounted() {
    localStorage.clear();
    setTimeout(() => {
      this.$router.push({
        name: "LoginCliente",
      });
    }, 9000);
  },
};
</script>

<style scoped>
.bodyEncaminhada {
  font-family: "Nunito", sans-serif;
  background-color: white;
  width: 600px;
  border-radius: 8px;
  padding-bottom: 25px;
  padding-top: 5px;
  margin-top: 30px;
}

.titleProposta {
  font-size: 22px;
  font-weight: bold;
  margin: 8px 0px;
}
.master-wrapper {
  width: 100%;
  height: 10%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 20px;
  margin-bottom: 10px;
}
.circle-wrapper {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}
.circle-wrapper:before,
.circle-wrapper:after {
  content: "";
  position: absolute;
  display: block;
  background-color: rgb(201, 201, 201);
  height: 1px;
  width: 100%;
}
.circle-wrapper:before {
  left: 0;
}
.circle-wrapper:after {
  right: 0;
}
.circle-ativo {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: dodgerblue;
  color: white;
  position: relative;
  z-index: 1;
  text-align: center;
  font-weight: 600;
}
.circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: white;
  color: black;
  border: 1px solid rgb(201, 201, 201);
  z-index: 1;
  text-align: center;
  line-height: 23px;
}
.alignMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

p {
  text-align: center;
  font-size: 22px;
  margin: 0;
}

.numberWhats {
  font-size: 19px;
  font-weight: bold;
}

@media (max-width: 600px) {
  .bodyEncaminhada {
    width: 95vw;
  }
}
</style>