<template>
  <LayoutCliente>
    <PropostaEncaminhada />
  </LayoutCliente>
</template>

<script>
import LayoutCliente from "@/Layouts/LayoutCliente.vue";
import PropostaEncaminhada from "@/components/Cliente/PropostaEncaminhada.vue";
export default {
  components: { LayoutCliente, PropostaEncaminhada },
};
</script>

<style>
</style>